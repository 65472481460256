import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const Loading = () => {
  const { isLoading } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (isLoading) {
    }
  }, [isLoading]);
  return (
    // <div className='w-[100vw] top-0 left-0 h-[100vh] fixed bg-[#1e1e1e45]  backdrop-blur-sm flex justify-center items-center'>
    <div className='bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center'>
      <div className='loader'></div>
    </div>
  );
};
export default Loading;
