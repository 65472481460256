import axiosInstance from '../configs/axiosConfig';

export const getNotification = async (id: string) => {
  const res = await axiosInstance.get(`/notifications/${id}`);
  return res.data;
};

export const getNewNotificationCount = async (id: string) => {
  const res = await axiosInstance.get(`/notifications/count/${id}`);

  return res.data;
};

export const updateNotification = async (id: string) => {
  await axiosInstance.put(`/notifications/${id}`, { newNotification: false });
};
