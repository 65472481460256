import { ICreateTask, IUpdateTask } from '../@types/dataTypes';
import axiosInstance from '../configs/axiosConfig';

export const createATask = async (task: ICreateTask) => {
  await axiosInstance.post('/tasks', task);
};

export const getAllTasks = async () => {
  const res = await axiosInstance.get('/tasks');
  return res.data;
};

export const updateTask = async (task: IUpdateTask) => {
  await axiosInstance.put(`/tasks/${task._id}`, task);
};

export const getTaskByName = async (name: string) => {
  const res = await axiosInstance.get(`/tasks/findByName/${name}`);
  return res.data;
};
