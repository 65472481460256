// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA7iWWmxxH7u0pF-yFfj0al3hbbi4hmRgo',
  authDomain: 'leaddres-72cfc.firebaseapp.com',
  projectId: 'leaddres-72cfc',
  storageBucket: 'leaddres-72cfc.appspot.com',
  messagingSenderId: '157963220398',
  appId: '1:157963220398:web:a7b1d1477656405429443f',
  measurementId: 'G-6MVB2HMTXP',
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
