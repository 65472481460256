import { useEffect, useRef, useState } from 'react';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Input from './Input';
import DateInput from './DateInput';
import TextArea from './TextArea';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCounsellorOptions } from '../api/counsellorsApi';
import Select from './Select';
import { OptionType } from '../@types/componentType';
import Button from './Button';
import { ShieldTick } from 'iconsax-react';
import { createATask } from '../api/taskApi';

type Props = {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};
const NewTaskPopoup = ({ setActive }: Props) => {
  const ModalRef = useRef(null);
  const { name } = useSelector((state: RootState) => state.auth.user);
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [deadline, setDeadline] = useState<Date>(new Date());
  const queryClient = useQueryClient();

  // const [documents, setDocuments] = useState<FileList | null>(null);
  const [assignedTo, setAssignedTo] = useState<OptionType>({
    value: 'none',
    label: 'Select who the task is for',
  });

  const { mutate: createTask } = useMutation(createATask, {
    onSuccess: () => {
      setActive(false);
      queryClient.invalidateQueries(['tasks']);
    },
  });

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const { data: counsellors } = useQuery(['counsellor-options'], getCounsellorOptions);

  const InputFields = [
    {
      label: 'Title',
      name: 'title',
      value: title,
      setValue: setTitle,
      type: 'text',
    },
  ];

  useOutsideAlerter(ModalRef);

  return (
    <div className={`bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center z-[50]`}>
      <div className='bg-[#eee]  py-6 w-[50%] flex flex-col gap-5 relative rounded-2xl px-8' ref={ModalRef}>
        <h3 className='text-xl font-[600] mb-2'>Create New Task</h3>
        <div className='flex flex-col gap-1'>
          {InputFields.map((input, index) => (
            <Input key={index} label={input.label} name={input.name} value={input.value} setValue={input.setValue} type={input.type} />
          ))}
          <DateInput label='Deadline' value={deadline} setValue={setDeadline} name='deadline' />
          <div className='my-2'>
            <Select value={assignedTo} setValue={setAssignedTo} options={counsellors} isDisabled={false} />
          </div>
          <TextArea label='Description' value={description} setValue={setDescription} name='description' />
          <div>
            <Button
              event={() => {
                createTask({
                  title,
                  description,
                  deadline,
                  assignedTo: assignedTo.label,
                  assignedBy: name,
                });
              }}
              text={'Save'}
              icon={ShieldTick}
              variant={'Linear'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewTaskPopoup;
