import { ICreateAgent } from '../@types/functionTypes';
import axiosInstance from '../configs/axiosConfig';

export const createAAgent = async (agent: ICreateAgent) => {
  await axiosInstance.post('/agents/', agent);
};

export const getAllAgents = async () => {
  const res = await axiosInstance.get('/agents/');
  return res.data;
};
