import { Eye, EyeSlash } from 'iconsax-react';
import { useState } from 'react';
import { InputProps } from '../@types/componentType';

const Input = ({ label, value, setValue, type, name }: InputProps) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className='flex flex-col gap-1'>
      <label htmlFor={name} className='text-base font-[300]'>
        {label}
      </label>
      <div className='w-full relative'>
        <input
          type={type !== 'password' ? type : show ? 'text' : 'password'}
          value={value}
          name={name}
          onChange={e => setValue(e.target.value)}
          className={`w-full bg-[#E4E4E4] px-[12px] py-[8px] outline-none text-fade rounded-lg`}
          style={{ border: '1px solid #00000025' }}
        />
        {type === 'password' && !show && <EyeSlash variant='Bold' className='absolute right-3 top-[50%] -translate-y-[50%] cursor-pointer' size={20} onClick={() => setShow(true)} />}
        {type === 'password' && show && <Eye variant='Bold' className='absolute right-3 top-[50%] -translate-y-[50%] cursor-pointer' size={20} onClick={() => setShow(false)} />}
      </div>
    </div>
  );
};
export default Input;
