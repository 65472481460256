import { ActionDataType } from '../@types/dataTypes';
import { ArrowRight, MessageNotif } from 'iconsax-react';
import { ActionTemplateType } from '../@types/componentType';

const ActionTemplate = ({ date, actions }: ActionTemplateType) => {
  return (
    <div className='flex flex-col w-full gap-3'>
      <div className='text-sm font-[300] w-full'>{date}</div>
      <div className='pl-5 flex w-full gap-4'>
        <div className='w-[5px] bg-danger rounded-md'></div>
        <div className='flex-1 flex flex-col gap-4'>
          {actions.map((item, index) => (
            <div key={index} className='px-6 py-6 rounded-xl flex flex-col gap-4' style={{ background: 'linear-gradient(180deg, #FEFEFE 0%, #DBDBDB 100%)' }}>
              <div className='font-[600] text-lg'>{item.title}</div>
              <div className='flex justify-between items-start'>
                <div className='flex gap-2 items-center w-1/2'>
                  <div className='w-5 h-5 bg-[#e35c5c] rounded-md flex justify-center items-center'>
                    <ArrowRight className='' color='#fff' size={16} />
                  </div>
                  {item.takenBy}
                </div>
                {item.remarks && (
                  <div className='flex gap-2 items-start w-1/2'>
                    <div>
                      <MessageNotif className='' color='#e35c5c' size={24} variant='Bold' />
                    </div>
                    <span>{item.remarks}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ActionTemplate;
