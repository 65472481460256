import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShieldCross, ShieldTick } from 'iconsax-react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { createAAgent } from '../api/agentApi';
import { RootState } from '../redux/store';
import Button from './Button';
import Input from './Input';

type Props = {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};
const NewAgent = ({ setActive }: Props) => {
  const ModalRef = useRef(null);
  const queryClient = useQueryClient();
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const [name, setName] = useState<string>('');
  const [area, setArea] = useState<string>('');

  const { mutate: addMutate } = useMutation(createAAgent, {
    onSuccess: () => {
      queryClient.invalidateQueries(['agents']).then(() => setActive(false));
    },
  });

  const InputFields = [
    {
      label: 'Name',
      name: 'name',
      value: name,
      setValue: setName,
      type: 'text',
    },
    {
      label: `Agent's Area`,
      name: 'area',
      value: area,
      setValue: setArea,
      type: 'text',
    },
  ];

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);
  return (
    <div className={`bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center`}>
      <div className='bg-[#eee]  py-6 w-[30%] flex flex-col gap-5 relative rounded-2xl px-8' ref={ModalRef}>
        <h3 className='font-[700] text-[24px]'>Create Agent</h3>
        <div className='flex flex-col gap-6'>
          <form>
            {InputFields.map((item: any, index) => (
              <Input {...item} key={index} />
            ))}
          </form>
          <div className='flex gap-3'>
            <Button
              event={() => {
                addMutate({ name: name as string, area: area as string });
              }}
              text={'Save'}
              icon={ShieldTick}
              variant={'Linear'}
            />
            <Button
              event={() => {
                setActive(false);
              }}
              text={'Cancel'}
              icon={ShieldCross}
              variant={'Linear'}
              isStrong={true}
            />
          </div>
        </div>
        <div className='absolute right-3 cursor-pointer' onClick={() => setActive(false)}>
          <div className='red-gradient w-6 h-6 relative rounded-lg'>
            <div className='w-[3px] bg-white h-[16px] rounded-md rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
            <div className='w-[3px] bg-white h-[16px] rounded-md -rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewAgent;
