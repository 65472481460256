export const themes = {
    light: {
        dark: false,
        body: '#eeeeee',
        body_gradient_start: '#FFFFFF',
        body_gradient_end: '#DADADA',
        text: '#16213E',
        primary: '#8C52FF',
        fade: '#14141560',
        danger: '#E10000',
        warning: '#E2D39E',
        info: '#B4C8E5',
        success: '#00C02A',
        border: '#14141525'
    },
}