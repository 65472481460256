import { createSlice } from '@reduxjs/toolkit/dist';

const initialState = {
  intakes: [],
  isFetching: false,
  error: false,
};

const intakeSlice = createSlice({
  name: 'intake',
  initialState,
  reducers: {
    intakeStart: state => {
      state.isFetching = true;
    },
    intakeSuccess: (state, action) => {
      state.isFetching = false;
      state.intakes = action.payload;
      state.error = false;
    },
    intakeFailure: state => {
      state.error = true;
      state.isFetching = false;
    },
  },
});

export const { intakeStart, intakeFailure, intakeSuccess } = intakeSlice.actions;
export default intakeSlice.reducer;
