import { DetailTemplateType } from '../@types/componentType';

const DetailTemplate = ({ title, data }: DetailTemplateType) => {
  return (
    <div className='linear-gradient w-full px-8 py-8 rounded-2xl flex flex-col gap-6'>
      <div className='flex items-center justify-between'>
        <h3 className='text-xl font-[700]'>{title}</h3>
        {/* <p className='text-danger font-[300] cursor-pointer'>Edit</p> */}
      </div>
      <div className='flex flex-col gap-3'>
        {data.map((item, index) => {
          let Icon = item.icon;
          return (
            <div className='flex items-center gap-3' key={index}>
              <Icon variant='Bold' size={20} color={'#E10000'} />
              <span>{item.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default DetailTemplate;
