import { useState, useEffect, useRef } from 'react';
import { ArrowDown2 } from 'iconsax-react';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { OptionType, SelectType } from '../@types/componentType';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const Select = ({ value, setValue, options, isDisabled }: SelectType) => {
  const OptionRef = useRef(null);
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const [isOptionActive, setIsOptionActive] = useState<boolean>(false);

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setIsOptionActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(OptionRef);

  const handleOptionClick = (item: OptionType) => {
    setValue(item);
    setIsOptionActive(false);
  };

  return (
    <div className={`w-full relative`} ref={OptionRef}>
      <div
        className={`linear-gradient px-4 w-full py-[0.4rem] flex items-center justify-between rounded-lg ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${isDisabled && 'text-fade'}`}
        onClick={() => setIsOptionActive(prev => !prev)}
      >
        {value?.label} <ArrowDown2 size={'20px'} />
      </div>
      {!isDisabled && isOptionActive && (
        <div className={`linear-gradient absolute rounded-xl py-2 flex flex-col  w-full max-h-40 overflow-auto top-[103%] z-[40] `}>
          {options &&
            options.map((item, index) => (
              <>
                {item.label !== value?.label && (
                  <div key={index} className='pl-4 hover:bg-[#14141520] py-2 cursor-pointer ' onClick={() => handleOptionClick(item)}>
                    {item.label}
                  </div>
                )}
              </>
            ))}
        </div>
      )}
    </div>
  );
};
export default Select;
