import axiosInstance from '../configs/axiosConfig';

export const getLeadNumberByType = async (key: string) => {
  const res = await axiosInstance.get(`/leads/stats/number/${key}`);
  return res.data;
};

export const getLeadsByType = async (key: string) => {
  const res = await axiosInstance.get(`/leads/type/${key}`);
  return res.data;
};
