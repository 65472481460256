import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DeleteModal from '../components/DeleteModal';
import ListTemplate from '../components/ListTemplate';
import NewCollection from '../components/NewCollection';

const Container = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CollectionList = () => {
  const location = useLocation();
  const [isDeleteActive, setIsDeleteActive] = useState<boolean>(false);
  const [isCreateActive, setIsCreateActive] = useState<boolean>(false);
  const [toBeDeleteId, setToBeDeleteId] = useState<string>('');
  return (
    <Container>
      <ListTemplate title={location.state.title} type='collection' setDelete={setIsDeleteActive} setCreate={setIsCreateActive} setToBeDeleteId={setToBeDeleteId} />
      {isDeleteActive && <DeleteModal setState={setIsDeleteActive} collectionId={toBeDeleteId as string} />}
      {isCreateActive && <NewCollection setActive={setIsCreateActive} intakeId={location.state.id} type={location.state.type} />}
    </Container>
  );
};
export default CollectionList;
