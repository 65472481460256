export const SourceList = [
  {
    label: 'Social Media',
    value: 'Social Media',
  },
  {
    label: 'Self Visit',
    value: 'Self Visit',
  },
  {
    label: 'Campaigns',
    value: 'Campaigns',
  },
];
