import { useRef, useEffect, useState } from 'react';
import { NewCounsellorType } from '../@types/componentType';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { InputProps } from '../@types/componentType';
import Input from './Input';
import Button from './Button';
import { Camera, DocumentFilter, Next, Save2 } from 'iconsax-react';
import { useDropzone } from 'react-dropzone';
import { uploadImage } from '../utils/utils';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { createACounsellor } from '../api/counsellorsApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { startLoading } from '../redux/features/authSlice';

const NewCounsellor = ({ setActive }: NewCounsellorType) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const ModalRef = useRef(null);
  const queryClient = useQueryClient();
  const [step, setStep] = useState<1 | 2>(1);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [photo, setPhoto] = useState<File>();
  const [previewPath, setPreviewPath] = useState<string>('');

  const { mutate: createCounsellor } = useMutation(createACounsellor, {
    onSuccess: () => {
      queryClient.invalidateQueries(['counsellors']);
    },
  });

  const InputFields: InputProps[] = [
    {
      label: 'Full Name',
      name: 'name',
      value: name,
      setValue: setName,
      type: 'text',
    },
    {
      label: 'Email',
      name: 'email',
      value: email,
      setValue: setEmail,
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      value: password,
      setValue: setPassword,
      type: 'password',
    },
  ];

  const onDrop = (accepted: any) => {
    const acceptedObj = accepted[0];
    acceptedObj['timestamp'] = Date.now();
    setPhoto(acceptedObj);
    setPreviewPath(URL.createObjectURL(acceptedObj as File));
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/png': ['.png', '.jpg', '.jpeg', '.svg', '.webp'] },
    maxFiles: 1,
  });

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);
  return (
    <>
      <div className={`bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center`}>
        <div className='bg-[#eee]  py-6 w-[30%] flex flex-col gap-5 relative rounded-2xl' ref={ModalRef}>
          {step === 1 ? (
            <>
              <div className='px-8'>
                <p className='text-base font-[300]'>Step {step} of 2</p>
                <h3 className='text-[24px] font-[700]'>Create a New Counsellor</h3>
              </div>
              <div className='px-8 py-4 flex flex-col gap-4' style={{ borderTop: '1px solid #00000020', borderBottom: '1px solid #00000020' }}>
                {InputFields.map((item, index) => (
                  <Input label={item.label} name={item.name} type={item.type} value={item.value} setValue={item.setValue} key={index} />
                ))}
              </div>
              <div className={'px-8'}>
                <Button
                  text={'Next'}
                  event={() => {
                    setStep(2);
                  }}
                  icon={Next}
                  variant={'Outline'}
                />
              </div>
            </>
          ) : (
            <>
              <div className='px-8'>
                <p className='text-base font-[300]'>Step {step} of 2</p>
                <h3 className='text-[24px] font-[700]'>Create a New Counsellor</h3>
              </div>
              <div className='px-8 py-4 flex flex-col gap-2' style={{ borderTop: '1px solid #00000020', borderBottom: '1px solid #00000020' }}>
                <div className='text-base font-[300]'>Profile Picture</div>
                <div
                  {...getRootProps()}
                  className='w-full h-[200px] bg-[#E4E4E4] cursor-pointer rounded-xl text-fade flex items-center justify-center relative'
                  style={{ border: '1px solid #00000025' }}
                >
                  {photo && (
                    <div className='absolute top-[10px] left-[50%]  w-[95%] p-[10px] -translate-x-[50%] rounded-lg cursor-default flex flex-wrap gap-2'>
                      <div className='w-28  flex items-center rounded  text-sm relative'>
                        <div className='w-20 h-20 relative'>
                          <img src={previewPath} alt='' className='object-cover object-top w-full h-full rounded-lg' />
                          <div className='absolute -top-[6px] -right-[6px]'>
                            <div className='red-gradient w-4 h-4 relative rounded-full cursor-pointer'>
                              <div className='w-[2px] bg-white h-[12px] rounded-md rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
                              <div className='w-[2px] bg-white h-[12px] rounded-md -rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isDragActive ? (
                    <p className='flex gap-2'>
                      <DocumentFilter variant='Bold' size={24} /> Drop the files here ...
                    </p>
                  ) : (
                    <div className='flex items-center justify-center flex-col gap-2'>
                      <Camera variant='Bold' size={30} />
                      <div className='flex flex-col items-center '>
                        <p>Drag and drop</p>
                        <p>or</p>
                        <p>CLick to upload profile picture</p>
                      </div>
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
              </div>
              <div className={'px-8'}>
                <Button
                  text={avatar === '' ? 'Save Image' : 'Save'}
                  event={() => {
                    if (avatar === '') {
                      uploadImage({ items: [photo as File], setAvatar, dispatch });
                      dispatch(startLoading());
                    } else {
                      createCounsellor({ user: { avatar, email, name, userType: 'counsellor', password } });
                      setActive(false);
                    }
                  }}
                  icon={avatar === '' ? Save2 : Next}
                  variant={avatar === '' ? 'Bold' : 'Outline'}
                />
              </div>
            </>
          )}
          <div className='absolute right-3 cursor-pointer' onClick={() => setActive(false)}>
            <div className='red-gradient w-6 h-6 relative rounded-lg'>
              <div className='w-[3px] bg-white h-[16px] rounded-md rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
              <div className='w-[3px] bg-white h-[16px] rounded-md -rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewCounsellor;
