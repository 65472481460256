import { ShieldCross, ShieldTick } from 'iconsax-react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { NewActionType } from '../@types/componentType';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { createIntake } from '../redux/apiCalls/apiIntake';
import { RootState } from '../redux/store';
import Button from './Button';
import Input from './Input';

const NewIntake = ({ setActive }: NewActionType) => {
  const ModalRef = useRef(null);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);

  const handleCreateIntake = () => {
    createIntake({ month, year }, dispatch);
    setActive(false);
  };
  return (
    <div className='bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center'>
      <div className='bg-[#eee]  py-6 w-[30%] flex flex-col gap-5 relative rounded-2xl px-8' ref={ModalRef}>
        <h3 className='font-[700] text-[24px]'>New Intake</h3>
        <div className='flex flex-col gap-6'>
          <form>
            <Input label='Intake Year' name='year' type='text' value={year} setValue={setYear} />
            <Input label='Intake Month' name='month' type='text' value={month} setValue={setMonth} />
          </form>
          <div className='flex gap-3'>
            <Button event={handleCreateIntake} text={'Save'} icon={ShieldTick} variant={'Linear'} />
            <Button
              event={() => {
                setActive(false);
              }}
              text={'Cancel'}
              icon={ShieldCross}
              variant={'Linear'}
              isStrong={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewIntake;
