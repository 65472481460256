import styled from 'styled-components';
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Toolbar,
  Inject,
  EditSettingsModel,
  ToolbarItems,
  ExcelExport,
  Grid,
  PdfExport,
  Group,
  Sort,
  Filter,
  FilterSettingsModel,
  Page,
  IEditCell,
  Search,
} from '@syncfusion/ej2-react-grids';
import Button from '../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Profile, ProfileAdd } from 'iconsax-react';
import { LeadTableType } from '../@types/componentType';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteALead, getAllLeads, getCollectionSpecificLeads, getIntakeSpecificLeads, getTypeSpecificLeads, updateALeadById } from '../api/leadApi';
import { toolbarClick } from '../utils/utils';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DataManager } from '@syncfusion/ej2-data';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { getAllCounsellors } from '../api/counsellorsApi';
import { useEffect, useState } from 'react';
import NewLead from '../components/NewLead';
import { getAllAgents } from '../api/agentApi';
import moment from 'moment';
import NewIntakeLead from '../components/NewIntakeLead';
import NewTypeLead from '../components/NewTypeLead';
import NewAllLead from '../components/NewAllLead';

const Container = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LeadTable = ({ type, socket }: LeadTableType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { name } = useSelector((state: RootState) => state.auth.user);
  const [newLeadActive, setNewLeadActive] = useState<boolean>(false);
  const { data: allData } = useQuery(['leads'], getAllLeads, { enabled: type === 'all' });
  const { data: collectionData } = useQuery(['leads', location.pathname.split('/')[3]], () => getCollectionSpecificLeads(location.pathname.split('/')[3]), { enabled: type === 'collection' });
  const { data: intakeData } = useQuery(['leads', location.pathname.split('/')[3]], () => getIntakeSpecificLeads(location.pathname.split('/')[3]), { enabled: type === 'intake' });
  const { data: typeData } = useQuery(['leads', location.pathname.split('/')[3]], () => getTypeSpecificLeads(location.pathname.split('/')[3]), { enabled: type === 'type' });

  const { data: counsellors } = useQuery(['counsellors'], getAllCounsellors);

  const { data: agents } = useQuery(['agents'], getAllAgents);

  const { mutate: updateMutate, data: updateResponse } = useMutation(updateALeadById, {
    onSuccess: () => {
      queryClient.invalidateQueries(['leads']);
    },
  });

  const { mutate: deleteMutate } = useMutation(deleteALead, {
    onSuccess: () => {
      queryClient.invalidateQueries(['leads', 'collections']);
    },
  });

  let grid: Grid | null;
  let counsellorElem: HTMLElement;
  let statusElem: HTMLElement;
  let agentElem: HTMLElement;
  let counsellorObj: DropDownList;
  let statusObj: DropDownList;
  let agentObj: DropDownList;
  const editOptions: EditSettingsModel = { allowAdding: true, allowEditing: true, allowDeleting: true, mode: 'Dialog', showDeleteConfirmDialog: true };
  const toolbarOptions: ToolbarItems[] = ['Edit', 'Update', 'Delete', 'Cancel', 'ExcelExport', 'PdfExport', 'Search'];

  const counsellorParams: IEditCell = {
    create: () => {
      counsellorElem = document.createElement('input');
      return counsellorElem;
    },
    destroy: () => {
      counsellorObj.destroy();
    },
    read: () => {
      return counsellorObj.text;
    },
    write: () => {
      counsellorObj = new DropDownList({
        dataSource: new DataManager([...counsellorColl, { counsellorName: 'none', counsellorValue: 'none' }]),
        fields: { value: 'counsellorValue', text: 'counsellorName' },
        floatLabelType: 'Auto',
        placeholder: 'Counsellor',
      });
      counsellorObj.appendTo(counsellorElem);
    },
  };

  const statusParams: IEditCell = {
    create: () => {
      statusElem = document.createElement('input');
      return statusElem;
    },
    destroy: () => {
      statusObj?.destroy();
    },
    read: () => {
      return statusObj.text;
    },
    write: () => {
      statusObj = new DropDownList({
        dataSource: new DataManager(statusColl),
        fields: { value: 'statusName', text: 'statusName' },
        floatLabelType: 'Auto',
        placeholder: 'Inquiry Positivity',
      });
      statusObj.appendTo(statusElem);
    },
  };

  const agentParams: IEditCell = {
    create: () => {
      agentElem = document.createElement('input');
      return agentElem;
    },
    destroy: () => {
      agentObj.destroy();
    },
    read: () => {
      return agentObj.text;
    },
    write: () => {
      agentObj = new DropDownList({
        dataSource: new DataManager([...agentColl, { agentName: 'none' }]),
        fields: { value: 'agentName', text: 'agentName' },
        floatLabelType: 'Auto',
        placeholder: 'Referrer',
      });
      agentObj.appendTo(agentElem);
    },
  };
  const counsellorColl: object[] = counsellors?.map((counsellor: any) => {
    return { counsellorName: counsellor.name, counsellorValue: counsellor.name };
  });
  const agentColl: object[] = agents?.map((agent: any) => {
    return { agentName: agent.name };
  });

  const statusColl: object[] = [{ statusName: 'Initial Phase' }, { statusName: 'Admitted' }, { statusName: 'Positive' }, { statusName: 'Negative' }];

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const viewProfileGridTemplate = (props: any) => {
    return (
      <Button
        text='Profile'
        event={() => {
          navigate('/leadprofile/' + props._id);
        }}
        icon={Profile}
      />
    );
  };

  const profileEditTemplate = (props: any) => {
    return <></>;
  };

  const statusGridTemplate = (props: any) => {
    const textColor = props.status === 'Initial Phase' ? '#0064F4' : props.status === 'Admitted' ? '#00C02A' : props.status === 'Positive' ? '#C2BB06' : props.status === 'Negative' ? '#E65F5F' : '';
    const dotColor = props.status === 'Initial Phase' ? '#B4C8E5' : props.status === 'Admitted' ? '#B5EBC1' : props.status === 'Positive' ? '#E9E586' : props.status === 'Negative' ? '#F6A1A1' : '';
    const borderColor = props.status === 'Initial Phase' ? '#7AA9EE' : props.status === 'Admitted' ? '#00C02A' : props.status === 'Positive' ? '#C2BB06' : props.status === 'Negative' ? '#E65F5F' : '';

    return (
      <div className={` flex items-center gap-2`} style={{ color: textColor }}>
        <div className='w-3 h-3 rounded-full' style={{ backgroundColor: dotColor, border: `1px solid ${borderColor}` }}></div> {props.status}
      </div>
    );
  };

  const dateGridTemplate = (props: any) => {
    return <div>{moment(props.createdAt).format('MM/DD')}</div>;
  };

  const handleEndEdit = async (args: any) => {
    if (args.requestType === 'save' && args.action === 'edit') {
      if (args.data.status === null) {
        args.data.status = args.previousData.status;
      }
      if (args.data.counsellor === null) {
        args.data.counsellor = args.previousData.counsellor;
      }
      if (args.data.referrer === null) {
        args.data.referrer = args.previousData.referrer;
      }

      let payload = args.data;
      Object.keys(payload).forEach(item => {
        (payload[item] === args.previousData[item] || item === 'actions') && delete payload[item];
      });
      updateMutate({ id: args.previousData._id as string, data: { ...args.data, takenBy: name } });
    }
    if (args.requestType === 'delete' && args.type === 'actionComplete') {
      args.data.forEach((d: any) => {
        deleteMutate(d?._id);
      });
    }
  };

  useEffect(() => {
    if (updateResponse && updateResponse.changed) {
      socket.emit('counsellorChange', { fromName: name, toName: updateResponse.name, type: 'Counsellor Assignment', notification: 'You have been assigned a new lead', leadId: updateResponse.lead });
    }
  }, [name, socket, updateResponse]);

  return (
    <Container>
      <div className='flex justify-start'>
        <Button
          text='Add New Lead'
          event={() => {
            setNewLeadActive(true);
          }}
          icon={ProfileAdd}
        />
      </div>

      <GridComponent
        dataSource={type === 'all' ? allData : type === 'collection' ? collectionData : type === 'intake' ? intakeData : typeData}
        editSettingsTemplate
        editSettings={editOptions}
        allowGrouping={true}
        actionComplete={handleEndEdit}
        toolbar={toolbarOptions}
        allowPdfExport={true}
        allowExcelExport={true}
        toolbarClick={args => toolbarClick(args, grid)}
        ref={g => (grid = g)}
        allowSorting={true}
        allowFiltering={true}
        filterSettings={filterOptions}
        loadingIndicator={{ indicatorType: 'Shimmer' }}
        height={500}
        allowPaging={true}
      >
        <ColumnsDirective>
          <ColumnDirective type='checkbox' width='50' />
          <ColumnDirective template={viewProfileGridTemplate} headerText='View Profile' editTemplate={profileEditTemplate} field='button' allowEditing={false} width='120' />
          <ColumnDirective field='name' headerText='Name' allowEditing={true} validationRules={{ required: true }} />
          <ColumnDirective field='location' headerText='Location' allowEditing={true} validationRules={{ required: true }} />
          <ColumnDirective field='phone' headerText='Phone Number' allowEditing={true} type='string' validationRules={{ required: true }} />
          <ColumnDirective field='email' headerText='Email' allowEditing={true} validationRules={{ required: true }} />
          <ColumnDirective field='status' headerText='Status' template={statusGridTemplate} editType={'dropdownedit'} edit={statusParams} />
          <ColumnDirective field='counsellor' headerText='Counsellor' editType={'dropdownedit'} edit={counsellorParams} />
          <ColumnDirective field='referrer' headerText='Sub Dealer' editType='dropdownedit' edit={agentParams} />
          {/* <ColumnDirective field='createdAt' headerText='Created Date' allowEditing={false} template={dateGridTemplate} /> */}
          {/* <ColumnDirective field='contactMedium' headerText='Contact Medium' /> */}
        </ColumnsDirective>
        <Inject services={[Edit, Toolbar, ExcelExport, PdfExport, Group, Filter, Sort, Page, Search]} />
      </GridComponent>
      {type === 'collection' && newLeadActive && <NewLead setActive={setNewLeadActive} t={type} />}
      {type === 'intake' && newLeadActive && <NewIntakeLead setActive={setNewLeadActive} />}
      {type === 'type' && newLeadActive && <NewTypeLead setActive={setNewLeadActive} />}
      {type === 'all' && newLeadActive && <NewAllLead setActive={setNewLeadActive} />}
    </Container>
  );
};
export default LeadTable;
