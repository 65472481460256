import { useState } from 'react';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Toolbar, Edit, ToolbarItems, PdfExport, Grid } from '@syncfusion/ej2-react-grids';
import { ProfileAdd } from 'iconsax-react';
import styled from 'styled-components';
import Button from '../components/Button';
import NewCounsellor from '../components/NewCounsellor';
import { useQuery } from '@tanstack/react-query';
import { getAllCounsellors } from '../api/counsellorsApi';

const CounsellorContainer = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Counsellors = () => {
  let grid: Grid | null;
  // const editOptions: EditSettingsModel = { allowEditing: true, allowDeleting: true, mode: 'Dialog', allowEditOnDblClick: false };
  const toolbarOptions: ToolbarItems[] = ['PdfExport'];
  const [createActive, setCreateActive] = useState<boolean>(false);

  const { data: counsellors } = useQuery(['counsellors'], getAllCounsellors);

  const toolbarClick = (args: ClickEventArgs) => {
    if (grid && args.item.id === 'grid_133672288_0_pdfexport') {
      grid.pdfExport();
    }
  };

  const counsellorTemplate = (props: any) => {
    return (
      <div className='flex items-center gap-2'>
        <img src={props?.avatar} alt={props.name} className='w-12 h-12 rounded-full object-cover object-top' />
        <p>{props.name}</p>
      </div>
    );
  };

  const statusGridTemplate = (props: any) => {
    const text = props?.isOnline ? 'Online' : 'Offline';
    const color = props?.isOnline ? '#00BB4B' : '#A3A3A3';
    const borderColor = props?.isOnline ? '#00BB4B71' : '#A3A3A3AD';
    return (
      <div className={` flex items-center gap-2`} style={{ color: color }}>
        <div className='w-3 h-3 rounded-full' style={{ backgroundColor: borderColor, border: `1px solid ${color}` }}></div> {text}
      </div>
    );
  };

  return (
    <CounsellorContainer>
      <div className='flex justify-end'>
        <Button
          text='Create New Counsellor'
          event={() => {
            setCreateActive(true);
          }}
          icon={ProfileAdd}
        />
      </div>
      <GridComponent dataSource={counsellors} toolbar={toolbarOptions} toolbarClick={toolbarClick} allowPdfExport={true} ref={g => (grid = g)}>
        <ColumnsDirective>
          {/* <ColumnDirective type='checkbox' width='5%' /> */}
          {/* <ColumnDirective headerText='Actions' template={actionTemplate} width={'100px'} textAlign='Center' /> */}
          <ColumnDirective field='username' headerText='Counsellor' width='20%' template={counsellorTemplate} />
          <ColumnDirective field='email' headerText='Email' width='20%' />
          <ColumnDirective field='total' headerText='Total Leads' width='15%' />
          <ColumnDirective field='converted' headerText='Leads Converted' width='15%' />
          <ColumnDirective field='isOnline' headerText='Status' width='15%' template={statusGridTemplate} />
        </ColumnsDirective>
        <Inject services={[Toolbar, Edit, PdfExport]} />
      </GridComponent>
      {createActive && <NewCounsellor setActive={setCreateActive} />}
    </CounsellorContainer>
  );
};
export default Counsellors;
