import { Icon } from 'iconsax-react';
import { ButtonProps } from '../@types/componentType';

const Button = ({ text, event, styles, icon, isStrong, variant }: ButtonProps) => {
  let Icon: Icon | undefined = icon;
  return (
    <button
      type='button'
      className={`${isStrong && 'text-[#fff]'} ${isStrong ? 'red-gradient' : 'linear-gradient'} border-solid border border-[#00000025] px-3 py-1 rounded-md flex items-center justify-center text-sm ${
        styles && styles
      }`}
      onClick={event}
    >
      {typeof Icon !== 'undefined' && (
        <span className='mr-2'>
          <Icon size={'18'} variant={variant ? variant : 'Bold'} />
        </span>
      )}
      {text}
    </button>
  );
};
export default Button;
