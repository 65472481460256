import { CloseCircle, DocumentCopy, DocumentFilter, Next, Previous, ShieldTick } from 'iconsax-react';
import { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { NewCollectionType } from '../@types/componentType';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { SourceList } from '../data/Intakes';
import Button from './Button';
import Input from './Input';
import Select from './Select';
import Papa from 'papaparse';
import { LeadDataType } from '../@types/dataTypes';
import ValidationComponent from './ValidationComponent';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createNewCollection } from '../api/collectionApi';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useDispatch } from 'react-redux';
import { stopLoading } from '../redux/features/authSlice';
import { validCompOptions } from '../data/data';
import { useLocation } from 'react-router-dom';

const NewCollection = ({ setActive, intakeId, type }: NewCollectionType) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const ModalRef = useRef(null);
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [title, setTitle] = useState<string>('');
  const [files, setFiles] = useState<DropFile[]>([]);
  const [sourceVal, setSourceVal] = useState({ label: 'Social Media', value: 'Social Media' });
  const [data, setData] = useState<LeadDataType[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);

  const { mutate: createCollectionMutate } = useMutation(createNewCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries(['collections', location.state.id, location.state.type.toLowerCase()]).then(() => {
        dispatch(stopLoading());
        setActive(false);
      });
    },
  });

  interface DropFile extends File {
    readonly path: string;
    readonly timestamp: number;
    readonly type: string;
  }

  const onDrop = (accepted: any) => {
    const acceptedObj = accepted[0];
    acceptedObj['timestamp'] = Date.now();
    setFiles((prev: any) => [...prev, acceptedObj]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
  });

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);

  return (
    <div className='bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center'>
      <div className='bg-[#eee]  py-6 w-[30%] flex flex-col gap-5 relative rounded-2xl' ref={ModalRef}>
        {step === 1 && (
          <>
            <div className='px-8'>
              <p className='text-base font-[300]'>Step {step} of 3</p>
              <h3 className='text-[24px] font-[700]'>Create New Collection</h3>
            </div>
            <div className='flex flex-col gap-3 px-8'>
              <Input label='Title' value={title} setValue={setTitle} type='text' name='title' />
              <div className='flex flex-col gap-1'>
                <p className='font-[300] text-base'>Source</p>
                <Select value={sourceVal} setValue={setSourceVal} options={SourceList} />
              </div>
            </div>
            <div className='flex gap-3 px-8 mt-4'>
              <Button
                text='Next'
                event={() => {
                  setStep(2);
                }}
                icon={Next}
                variant={'Linear'}
                styles='font-[600]'
              />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className='px-8'>
              <p className='text-base font-[300]'>Step {step} of 3</p>
              <h3 className='text-[24px] font-[700]'>Submit a CSV file</h3>
            </div>
            <div className='flex flex-col gap-1 px-8'>
              <p className='font-[300] text-base'>
                Lead <span className='text-danger'>(*csv)</span>
              </p>
              <div
                {...getRootProps()}
                className='bg-[#e4e4e4] h-48 rounded-xl flex items-center justify-center text-fade text-base outline-none font-[400] cursor-pointer relative'
                style={{ border: '2px dotted #00000025' }}
              >
                {files.length !== 0 && (
                  <div className='absolute top-[10px] left-[50%] bg-[#D9D9D9] w-[95%] p-[10px] -translate-x-[50%] rounded-lg cursor-default flex flex-wrap gap-2'>
                    {files.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className='w-28 h-[30px] flex items-center rounded pl-3 text-sm relative'
                          style={{ background: 'linear-gradient(180deg, #FAFAFA 0%, #DFDFDF 100%)', border: '1px solid #00000025' }}
                        >
                          {item.name.length > 10 ? item.name.slice(0, 11) + '...' : item.name}
                          <CloseCircle
                            variant='Bold'
                            color={'#E10000'}
                            size={16}
                            className='absolute -top-1 -right-1 cursor-pointer'
                            onClick={() => setFiles(files.filter(i => i.timestamp !== item.timestamp))}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <input {...getInputProps()} />

                {isDragActive ? (
                  <p className='flex gap-2'>
                    <DocumentFilter variant='Bold' size={24} /> Drop the files here ...
                  </p>
                ) : (
                  <div className='flex flex-col items-center'>
                    <span className='flex gap-2'>
                      <DocumentCopy variant='Bold' size={24} /> Drag and Drop
                    </span>
                    <span>or</span>
                    <span>Click here to select file</span>
                  </div>
                )}
              </div>
            </div>
            <div className='flex gap-3 px-8'>
              <Button
                text='Prev'
                event={() => {
                  setActive(false);
                }}
                isStrong={true}
                icon={Previous}
                variant='Linear'
                styles=' black-gradient'
              />
              <Button
                text='Next'
                event={() => {
                  setStep(3);
                  Papa.parse(files[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: results => {
                      setData(results?.data as LeadDataType[]);
                      setHeaders(results?.meta?.fields as string[]);
                    },
                  });
                }}
                icon={Next}
                variant={'Linear'}
                styles='font-[600]'
              />
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className='px-8'>
              <p className='text-base font-[300]'>Step {step} of 3</p>
              <h3 className='text-[24px] font-[700]'>Select Data Type</h3>
            </div>
            <div className='text-sm font-light px-8'>
              <p>We found these headers in the dataset you just uploaded.</p>
              <p>Please select appropriate data type of each header</p>
            </div>
            <div className='w-full h-[300px] px-8 py-4 flex relative justify-center ' style={{ borderTop: '1px solid #00000020', borderBottom: '1px solid #00000020' }}>
              <div className='flex flex-col h-full w-full  overflow-auto   items-center gap-y-4'>
                {headers?.map((item, index) => (
                  <ValidationComponent key={index} label={item} data={data} setData={setData} />
                ))}
              </div>
            </div>
            <div className='flex gap-3 px-8'>
              <Button
                text='Prev  '
                event={() => {
                  setActive(false);
                }}
                isStrong={true}
                icon={Previous}
                variant='Linear'
                styles='black-gradient'
              />
              <Button
                text='Save'
                event={() => {
                  setStep(3);
                  headers.forEach((d: string) => {
                    if (Object.keys(data[0]).includes(d) && !validCompOptions.some(e => e.value === d)) {
                      data.forEach(item => {
                        delete item[d];
                      });
                    }
                  });
                  createCollectionMutate({ data, intakeId, title, type, source: sourceVal.value, dispatch });
                }}
                icon={ShieldTick}
                variant={'Linear'}
                styles='font-[600]'
              />
            </div>
          </>
        )}
        <div className='absolute right-3 cursor-pointer' onClick={() => setActive(false)}>
          <div className='red-gradient w-6 h-6 relative rounded-lg'>
            <div className='w-[3px] bg-white h-[16px] rounded-md rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
            <div className='w-[3px] bg-white h-[16px] rounded-md -rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewCollection;
