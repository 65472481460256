import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { Grid } from '@syncfusion/ej2-react-grids';
import { Column } from '@syncfusion/ej2-react-grids';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { UploadParamsType } from '../@types/functionTypes';
import { storage } from '../configs/firebaseConfig';
import { stopLoading } from '../redux/features/authSlice';

export const toolbarClick = (args: ClickEventArgs, grid: Grid | null) => {
  const selectedRows = grid?.getSelectedRecords();
  const isExcelExport =
    args.item.id === 'grid_1141322626_0_excelexport' ||
    args.item.id === 'grid_1257268054_0_excelexport' ||
    args.item.id === 'grid_727499113_0_excelexport' ||
    args.item.id === 'grid_1050900780_0_excelexport';
  const isPdfExport =
    args.item.id === 'grid_1141322626_0_pdfexport' || args.item.id === 'grid_1257268054_0_pdfexport' || args.item.id === 'grid_727499113_0_pdfexport' || args.item.id === 'grid_1050900780_0_pdfexport';
  if (grid && isExcelExport && selectedRows?.length === 0) {
    (grid.columns[1] as Column).visible = false;
    grid.excelExport();
  } else if (grid && isExcelExport && selectedRows?.length !== 0) {
    (grid.columns[1] as Column).visible = false;
    const exportProperties = {
      dataSource: selectedRows,
    };
    grid.excelExport(exportProperties);
  }
  if (grid && isPdfExport && selectedRows?.length === 0) {
    (grid.columns[1] as Column).visible = false;
    grid.pdfExport();
  } else if (grid && isPdfExport && selectedRows?.length !== 0) {
    (grid.columns[1] as Column).visible = false;
    const exportProperties = {
      dataSource: selectedRows,
    };
    grid.pdfExport(exportProperties);
  }
};

export const uploadImage = ({ items, setAvatar, dispatch }: UploadParamsType) => {
  items.forEach(i => {
    const fileName = new Date().getTime() + 'file avatar' + i.name;
    const postRef = ref(storage, `/items/${fileName}`);
    const uploadTask = uploadBytesResumable(postRef, i);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      err => {
        console.log(err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(url => {
          setAvatar(url);
          dispatch(stopLoading());
        });
      }
    );
  });
};
