import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ListTemplate from '../components/ListTemplate';
import { useState } from 'react';
import DeleteModal from '../components/DeleteModal';
import NewIntake from '../components/NewIntake';

const Container = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const IntakeList = () => {
  const { type } = useLocation().state;
  const title: string = type === 'NET' ? 'Dish Home Fiber Net' : type === 'TV' ? 'Dish Home NET TV' : 'Dish Home Combo Package';
  const [isDeleteActive, setIsDeleteActive] = useState<boolean>(false);
  const [isCreateActive, setIsCreateActive] = useState<boolean>(false);
  const [toBeDeleteId, setToBeDeleteId] = useState<string>('');
  return (
    <Container>
      <ListTemplate title={title} type={'intake'} setDelete={setIsDeleteActive} setCreate={setIsCreateActive} setToBeDeleteId={setToBeDeleteId} />
      {/* {isDeleteActive && <DeleteModal setState={setIsDeleteActive} collectionId={} />} */}
      {isCreateActive && <NewIntake setActive={setIsCreateActive} />}
    </Container>
  );
};
export default IntakeList;
