import { useRef } from 'react';
import styled from 'styled-components';
import { Notification } from 'iconsax-react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useEffect, useState } from 'react';
import NotificationDropdown from './NotificationDropdown';
import { NavbarType } from '../@types/componentType';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getNewNotificationCount } from '../api/notificationApi';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { Maximize, Minimize } from '../assets';

const NavbarContainer = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;
`;

const Navbar = ({ socket }: NavbarType) => {
  const date = new Date();
  const ModalRef = useRef(null);
  const queryClient = useQueryClient();
  const { user } = useSelector((state: RootState) => state.auth);
  const { isLoading } = useSelector((state: RootState) => state.auth);

  let weekday: string = date.toLocaleString('default', { weekday: 'long' });
  let day: number = date.getDate();
  let month: string = date.toLocaleString('default', { month: 'long' });
  let year: number = date.getFullYear();
  const [recount, setRecount] = useState<boolean>(false);
  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  // const [badgeNum, setBadgeNum] = useState<number>(0);

  const { data: badgeNum } = useQuery(['notificationCount', user._id], () => getNewNotificationCount(user._id));

  const handleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
  };

  useEffect(() => {
    socket?.on('notification', msg => {
      queryClient.invalidateQueries(['notificationCount', 'notifications']);
    });
    if (recount) {
      queryClient.invalidateQueries(['notificationCount']);
      setRecount(false);
    }
  }, [socket, recount]);

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullScreen(prev => !prev);
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setNotificationShow(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);

  return (
    <NavbarContainer>
      <div className='flex items-center gap-3'>
        <h2 className='text-2xl font-semibold'>{weekday}</h2>
        <p className='font-normal'>
          {day}th {month} {year}
        </p>
      </div>
      <div className='flex gap-10'>
        <div className='flex gap-10 items-center'>
          <div className='font-medium flex items-center gap-2'>
            <div>
              <img src={user?.avatar} alt='' className='w-10 h-10 object-cover object-top rounded-full' />
            </div>
            {user.name}
          </div>
          <div className='relative' ref={ModalRef}>
            <TooltipComponent content={'Notification'} target='#notification-tooltip' position='TopCenter'>
              <div id='notification-tooltip' onClick={() => setNotificationShow(prev => !prev)} className='relative'>
                {badgeNum !== 0 && <div className='absolute w-3 h-3 bg-danger text-white flex justify-center items-center text-[8px] rounded-full -top-[4px] -right-[3px]'>{badgeNum}</div>}
                <Notification variant='Bold' size='21px' className='cursor-pointer' />
              </div>
            </TooltipComponent>
            {notificationShow && <NotificationDropdown setActive={setNotificationShow} badgeNum={badgeNum} setRecount={setRecount} />}
          </div>
          <TooltipComponent content={'Full Screen'} target='#fullscreen-tooltip' position='TopRight'>
            <button type='button' id='fullscreen-tooltip' onClick={handleFullScreen}>
              {isFullScreen ? <img src={Minimize} width='18' alt='maximize' /> : <img src={Maximize} width='18' alt='maximize' />}
            </button>
          </TooltipComponent>
        </div>
      </div>
    </NavbarContainer>
  );
};
export default Navbar;
