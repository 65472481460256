import { ICreateCounsellor } from '../@types/functionTypes';
import axiosInstance, { unverifiedInstance } from '../configs/axiosConfig';

export const createACounsellor = async ({ user }: ICreateCounsellor) => {
  await unverifiedInstance.post(`/auth/register`, user);
};

export const getAllCounsellors = async () => {
  const res = await axiosInstance.get('/users/counsellors');
  return res.data;
};

export const getCounsellorOptions = async () => {
  const res = await axiosInstance.get('/users/counsellors/options');
  return res.data;
};
