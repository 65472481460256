import { LoginUserType } from '../../@types/dataTypes';
import { unverifiedInstance } from '../../configs/axiosConfig';
import { authFailure, authStart, authSuccess, logout } from '../features/authSlice';
import { AppDispatch } from '../store';

export const login = async (dispatch: AppDispatch, user: LoginUserType) => {
  dispatch(authStart());
  try {
    const res = await unverifiedInstance.post('/auth/login', user);
    dispatch(authSuccess(res.data));
    // return res.data;
  } catch (err: any) {
    dispatch(authFailure());
    return err.response.status;
  }
};

export const tokenLogout = async (refreshToken: string, dispatch: AppDispatch) => {
  await unverifiedInstance.post('/auth/logout', { token: refreshToken });
  dispatch(logout());
};
