import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LeadCard, OptionType } from '../@types/componentType';
import Select from '../components/Select';
import { EmitType } from '@syncfusion/ej2-base';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, DateTime, SplineAreaSeries, Legend, Tooltip, Crosshair, ILegendRenderEventArgs } from '@syncfusion/ej2-react-charts';
import { areaCustomSeries, areaPrimaryXAxis, areaPrimaryYAxis, Months } from '../data/Chart';
import { getIntake } from '../redux/apiCalls/apiIntake';
import { useDispatch } from 'react-redux';
import HomeCard from '../components/HomeCard';
import { useQuery } from '@tanstack/react-query';
import { getChartData } from '../api/leadApi';

const HomeContainer = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  gap: 3.5rem;
`;
const Right = styled.div`
  flex: 2;
`;

const Left = styled.div`
  flex: 1;
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Home = () => {
  const dispatch = useDispatch();
  const [chartDisplay, setChartDisplay] = useState<OptionType>({
    label: 'Jan',
    value: '1',
  });
  const { data: LeadsData } = useQuery(['chart-data', +chartDisplay.value], () => getChartData(+chartDisplay.value));

  const CardData: LeadCard[] = [
    {
      label: 'Net TV',
      slug: 'TV',
    },
    {
      label: 'Fiber Net',
      slug: 'NET',
    },
    {
      label: 'Combo',
      slug: 'COMBO',
    },
  ];
  const legendRender: EmitType<ILegendRenderEventArgs> = (args: ILegendRenderEventArgs): void => {
    args.shape = 'Rectangle';
  };

  const areaCustomSeries = [
    {
      dataSource: LeadsData && LeadsData[2]?.data,
      xName: 'x',
      yName: 'y',
      name: 'Net TV',
      opacity: '0.8',
      type: 'SplineArea',
      width: '2',
      fill: '#E65F5F',
    },

    {
      dataSource: LeadsData && LeadsData[1]?.data,
      xName: 'x',
      yName: 'y',
      name: 'Fiber Net',
      opacity: '0.8',
      type: 'SplineArea',
      width: '2',
      fill: '#E2D39E',
    },
    {
      dataSource: LeadsData && LeadsData[0]?.data,
      xName: 'x',
      yName: 'y',
      name: 'Combo',
      opacity: '0.8',
      type: 'SplineArea',
      width: '2',
      fill: '#B4C8E5',
    },
  ];

  useEffect(() => {
    getIntake(dispatch);
  }, []);
  return (
    <HomeContainer>
      <Right>
        <div className='w-full flex justify-between gap-3 mb-10'>
          {CardData.map((item, index) => (
            <HomeCard {...item} key={index} />
          ))}
        </div>
        <div className='w-full'>
          <div className='flex justify-between items-center'>
            <h3 className='text-[20px]'>Total Leads Generated Per Month</h3>
            <div className='w-32'>
              <Select value={chartDisplay} setValue={setChartDisplay} options={Months} />
            </div>
          </div>
          <div className='mt-6 w-full'>
            {LeadsData?.length !== 0 && (
              <ChartComponent
                id='charts'
                primaryXAxis={areaPrimaryXAxis}
                primaryYAxis={areaPrimaryYAxis}
                chartArea={{ border: { width: 0 } }}
                // legendSettings={{ background: 'white' }}
                legendSettings={{
                  shapeWidth: 22,
                  shapeHeight: 22,
                  position: 'Top',
                  alignment: 'Near',
                  textStyle: { fontFamily: 'Lato', size: '14px', fontWeight: '300' },
                  margin: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 40,
                  },
                }}
                tooltip={{ enable: true }}
                crosshair={{ enable: true, line: { width: 2, color: '#14141520' } }}
                legendRender={legendRender}
              >
                <Inject services={[SplineAreaSeries, DateTime, Legend, Tooltip, Crosshair]} />
                <SeriesCollectionDirective>
                  {/* <SeriesDirective dataSource={(LeadsData || [])[0].data} xAxisName='x' yName='y' name='Combo' opacity={0.8} type='SplineArea' width={2} fill='#B4C8E5' /> */}
                  {areaCustomSeries.map((item, index) => (
                    <SeriesDirective {...item} key={index} />
                  ))}
                </SeriesCollectionDirective>
              </ChartComponent>
            )}
          </div>
        </div>
      </Right>
      <Left></Left>
    </HomeContainer>
  );
};
export default Home;
