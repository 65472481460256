// import { EdgeLabelPlacement, FontModel, IntervalType, MajorGridLinesModel, ValueType } from '@syncfusion/ej2-react-charts/src';
import { AxisModel } from '@syncfusion/ej2-react-charts';

export const areaPrimaryXAxis: AxisModel = {
  valueType: 'DateTime',
  labelFormat: 'dd',
  majorGridLines: { width: 0 },
  interval: 5,
  intervalType: 'Days',
  edgeLabelPlacement: 'Shift',
  crosshairTooltip: { enable: true, fill: '#14141560' },
  labelStyle: { color: 'gray' },
};
export const areaPrimaryYAxis: AxisModel = {
  labelFormat: '{value}',
  lineStyle: { width: 0 },
  maximum: 500,
  interval: 50,
  minimum: 0,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelStyle: { color: 'gray' },
  crosshairTooltip: { enable: true, fill: '#14141560' },
};

const areaChartData = [
  [
    { x: new Date(2021, 1, 1), y: 110 },
    { x: new Date(2021, 1, 2), y: 230 },
    { x: new Date(2021, 1, 3), y: 150 },
    { x: new Date(2021, 1, 4), y: 86 },
    { x: new Date(2021, 1, 5), y: 93 },
    { x: new Date(2021, 1, 6), y: 26 },
    { x: new Date(2021, 1, 7), y: 160 },
    { x: new Date(2021, 1, 8), y: 192 },
    { x: new Date(2021, 1, 9), y: 550 },
    { x: new Date(2021, 1, 10), y: 188 },
    { x: new Date(2021, 1, 11), y: 119 },
    { x: new Date(2021, 1, 12), y: 130 },
    { x: new Date(2021, 1, 13), y: 152 },
    { x: new Date(2021, 1, 14), y: 66 },
    { x: new Date(2021, 1, 15), y: 193 },
    { x: new Date(2021, 1, 16), y: 300 },
    { x: new Date(2021, 1, 17), y: 60 },
    { x: new Date(2021, 1, 18), y: 8 },
    { x: new Date(2021, 1, 19), y: 10 },
    { x: new Date(2021, 1, 20), y: 18 },
    { x: new Date(2021, 1, 21), y: 20 },
    { x: new Date(2021, 1, 22), y: 30 },
    { x: new Date(2021, 1, 23), y: 60 },
    { x: new Date(2021, 1, 24), y: 136 },
    { x: new Date(2021, 1, 25), y: 33 },
    { x: new Date(2021, 1, 26), y: 126 },
    { x: new Date(2021, 1, 27), y: 80 },
    { x: new Date(2021, 1, 28), y: 92 },
    { x: new Date(2021, 1, 29), y: 150 },
    { x: new Date(2021, 1, 30), y: 288 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 117 },
    { x: new Date(2021, 1, 2), y: 164 },
    { x: new Date(2021, 1, 3), y: 247 },
    { x: new Date(2021, 1, 4), y: 77 },
    { x: new Date(2021, 1, 5), y: 155 },
    { x: new Date(2021, 1, 6), y: 70 },
    { x: new Date(2021, 1, 7), y: 178 },
    { x: new Date(2021, 1, 8), y: 247 },
    { x: new Date(2021, 1, 9), y: 80 },
    { x: new Date(2021, 1, 10), y: 102 },
    { x: new Date(2021, 1, 11), y: 233 },
    { x: new Date(2021, 1, 12), y: 66 },
    { x: new Date(2021, 1, 13), y: 182 },
    { x: new Date(2021, 1, 14), y: 267 },
    { x: new Date(2021, 1, 15), y: 292 },
    { x: new Date(2021, 1, 16), y: 77 },
    { x: new Date(2021, 1, 17), y: 202 },
    { x: new Date(2021, 1, 18), y: 253 },
    { x: new Date(2021, 1, 19), y: 132 },
    { x: new Date(2021, 1, 20), y: 253 },
    { x: new Date(2021, 1, 21), y: 100 },
    { x: new Date(2021, 1, 22), y: 236 },
    { x: new Date(2021, 1, 23), y: 251 },
    { x: new Date(2021, 1, 24), y: 233 },
    { x: new Date(2021, 1, 25), y: 126 },
    { x: new Date(2021, 1, 26), y: 155 },
    { x: new Date(2021, 1, 27), y: 13 },
    { x: new Date(2021, 1, 28), y: 236 },
    { x: new Date(2021, 1, 29), y: 243 },
    { x: new Date(2021, 1, 30), y: 139 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 295 },
    { x: new Date(2021, 1, 2), y: 270 },
    { x: new Date(2021, 1, 3), y: 148 },
    { x: new Date(2021, 1, 4), y: 144 },
    { x: new Date(2021, 1, 5), y: 71 },
    { x: new Date(2021, 1, 6), y: 258 },
    { x: new Date(2021, 1, 7), y: 140 },
    { x: new Date(2021, 1, 8), y: 100 },
    { x: new Date(2021, 1, 9), y: 2 },
    { x: new Date(2021, 1, 10), y: 235 },
    { x: new Date(2021, 1, 11), y: 60 },
    { x: new Date(2021, 1, 12), y: 217 },
    { x: new Date(2021, 1, 13), y: 31 },
    { x: new Date(2021, 1, 14), y: 277 },
    { x: new Date(2021, 1, 15), y: 129 },
    { x: new Date(2021, 1, 16), y: 59 },
    { x: new Date(2021, 1, 17), y: 290 },
    { x: new Date(2021, 1, 18), y: 274 },
    { x: new Date(2021, 1, 19), y: 256 },
    { x: new Date(2021, 1, 20), y: 124 },
    { x: new Date(2021, 1, 21), y: 210 },
    { x: new Date(2021, 1, 22), y: 11 },
    { x: new Date(2021, 1, 23), y: 258 },
    { x: new Date(2021, 1, 24), y: 191 },
    { x: new Date(2021, 1, 25), y: 74 },
    { x: new Date(2021, 1, 26), y: 80 },
    { x: new Date(2021, 1, 27), y: 161 },
    { x: new Date(2021, 1, 28), y: 297 },
    { x: new Date(2021, 1, 29), y: 90 },
    { x: new Date(2021, 1, 30), y: 137 },
  ],
];

export const areaCustomSeries = [
  {
    dataSource: areaChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Net TV',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
    fill: '#E65F5F',
  },

  {
    dataSource: areaChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'Fiber Net',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
    fill: '#E2D39E',
  },
  {
    dataSource: areaChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Combo',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
    fill: '#B4C8E5',
  },
];

export const Months = [
  { label: 'Jan', value: '1' },
  { label: 'Feb', value: '2' },
  { label: 'Mar', value: '3' },
  { label: 'Apr', value: '4' },
  { label: 'May', value: '5' },
  { label: 'Jun', value: '6' },
  { label: 'Jul', value: '7' },
  { label: 'Aug', value: '8' },
  { label: 'Sept', value: '9' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' },
];
