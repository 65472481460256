import axiosInstance from '../configs/axiosConfig';

export const getIntakeOptions = async () => {
  const res = await axiosInstance.get('/options/intake');
  return res.data;
};

export const getCollectionOptions = async (intake: string, type: string) => {
  const res = await axiosInstance.get(`/options/collection/${intake}/${type}`);
  return res.data;
};
