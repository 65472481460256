import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShieldCross, ShieldTick } from 'iconsax-react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AddActionType, NewActionType } from '../@types/componentType';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { createNewAction } from '../api/actionsApi';
import { RootState } from '../redux/store';
import Button from './Button';
import Input from './Input';

const NewAction = ({ setActive, leadId }: AddActionType) => {
  const ModalRef = useRef(null);
  const queryClient = useQueryClient();
  const { name } = useSelector((state: RootState) => state.auth.user);
  const [title, setTitle] = useState<string>('');
  const [remarks, setRemarks] = useState<string>('');

  const { mutate: createCollectionMutate } = useMutation(createNewAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['actions']);
      setActive(false);
    },
  });

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);
  return (
    <div className='bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center'>
      <div className='bg-[#eee]  py-6 w-[30%] flex flex-col gap-5 relative rounded-2xl px-8' ref={ModalRef}>
        <h3 className='font-[700] text-[24px]'>New Action</h3>
        <div className='flex flex-col gap-6'>
          <form>
            <Input label='Title' name='title' type='' value={title} setValue={setTitle} />
            <div className='flex flex-col gap-1'>
              <label htmlFor='actiom'>Action</label>
              <textarea
                name='action'
                value={remarks}
                onChange={e => setRemarks(e.target.value)}
                className='bg-[#E4E4E4] resize-none w-full outline-none px-[12px] py-[8px] text-fade rounded-lg'
                rows={5}
                style={{ border: '1px solid #00000025' }}
              ></textarea>
            </div>
          </form>
          <div className='flex gap-3'>
            <Button
              event={() => {
                createCollectionMutate({ leadId, title, remarks, takenBy: name });
              }}
              text={'Save'}
              icon={ShieldTick}
              variant={'Linear'}
            />
            <Button
              event={() => {
                setActive(false);
              }}
              text={'Cancel'}
              icon={ShieldCross}
              variant={'Linear'}
              isStrong={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewAction;
