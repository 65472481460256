type Props = {
  label: string;
  value: Date;
  setValue: React.Dispatch<React.SetStateAction<Date>>;
  name: string;
};
const DateInput = (props: Props) => {
  return (
    <div className='flex flex-col gap-1'>
      <label className='text-sm font-[500] text-[#333]'>{props.label}</label>
      <input
        type='date'
        value={props.value.toISOString().split('T')[0]}
        onChange={e => props.setValue(new Date(e.target.value))}
        name={props.name}
        className='w-full bg-[#E4E4E4] px-[12px] py-[8px] outline-none text-fade rounded-lg'
        style={{ border: '1px solid #00000025' }}
      />
    </div>
  );
};
export default DateInput;
