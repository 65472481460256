import styled from 'styled-components';

import { KanbanComponent, ColumnsDirective, ColumnDirective, CardClickEventArgs } from '@syncfusion/ej2-react-kanban';
import Button from '../components/Button';
import { useState } from 'react';
import NewTaskPopoup from '../components/NewTaskPopoup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TaskType } from '../@types/dataTypes';
import { getAllTasks, updateTask } from '../api/taskApi';
import { DragEventArgs } from '@syncfusion/ej2-react-popups';
const Container = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ViewTasks = () => {
  const [createActive, setCreateActive] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { data: tasks } = useQuery<TaskType[]>(['tasks'], getAllTasks);
  const { mutate: updateATask } = useMutation(updateTask, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
    },
  });

  const cardTemplate = (props: any) => {
    console.log(props);
    return (
      <div>
        <div className='flex justify-between'>
          <h3 className='font-[600] text-lg'>{props.title}</h3>
          <p className='text-fade'>{props.assignedTo}</p>
        </div>
        <p className='text-fade'>{props.description}</p>
      </div>
    );
  };

  const dialogTemplate = (props: any) => {
    return (
      <div>
        <div className='flex justify-between'>
          <h3 className='font-[600] text-lg'>{props.title}</h3>
          <p className='text-fade'>{props.assignedTo}</p>
        </div>
        <p className='text-fade'>{props.description}</p>
      </div>
    );
  };
  const cardDoubleClick = (args: CardClickEventArgs) => {
    console.log(args);
  };

  const dragStop = (args: any) => {
    console.log(args);
    updateATask({ _id: args.data[0]._id, status: args.data[0].status });
  };

  return (
    <Container>
      <div className='w-full flex justify-between'>
        <h3 className='font-[600] text-xl'>View All Tasks.</h3>
        <Button
          text='Add Task'
          event={() => {
            setCreateActive(true);
          }}
        />
      </div>
      <KanbanComponent
        id='kanban'
        keyField='status'
        cssClass='kanban-overview'
        dialogSettings={{ template: dialogTemplate }}
        dataSource={tasks}
        enableTooltip={true}
        swimlaneSettings={{ keyField: 'assignedTo', allowDragAndDrop: false }}
        cardSettings={{
          headerField: 'title',
          contentField: 'description',
        }}
        dragStop={dragStop}
        cardDoubleClick={cardDoubleClick}
      >
        <ColumnsDirective>
          <ColumnDirective headerText='To Do' keyField='pending' />
          <ColumnDirective headerText='In Progress' keyField='in-progress' />
          <ColumnDirective headerText='Completed' keyField='Completed' />
        </ColumnsDirective>
      </KanbanComponent>

      {createActive && <NewTaskPopoup setActive={setCreateActive} />}
    </Container>
  );
};
export default ViewTasks;
