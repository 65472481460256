import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { NotificationDropdownType } from '../@types/componentType';
import { getNotification } from '../api/notificationApi';
import { RootState } from '../redux/store';
import NotificationItem from './NotificationItem';

const NotificationDropdown = ({ setActive, badgeNum, setRecount }: NotificationDropdownType) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { data: notifications } = useQuery(['notifications'], () => getNotification(user?._id));

  return (
    <div className='absolute right-0 w-[20rem] z-[102] bg-[#F7F7F7] py-4 rounded-xl max-h-[80vh] overflow-y-auto' style={{ border: '1px solid #00000015' }}>
      <div className='flex justify-between pb-4 px-4' style={{ borderBottom: '1px solid #00000015' }}>
        <div className='w-6 h-6 bg-danger text-white flex justify-center items-center rounded-[4px]'>{badgeNum}</div>
        <div className='cursor-pointer'>Mark all as read</div>
      </div>
      <div className=' py-4 flex flex-col  ' style={{ borderBottom: '1px solid #00000015' }}>
        {notifications?.length > 0 ? (
          notifications?.map((item: any, index: number) => <NotificationItem {...item} key={index} setActive={setActive} setRecount={setRecount} />)
        ) : (
          <div className='px-4 flex justify-center items-center text-fade'>No new notifications</div>
        )}
      </div>
      <div className='pt-2 flex justify-center items-center'>
        <p className='cursor-pointer text-danger'> Load More </p>
      </div>
    </div>
  );
};
export default NotificationDropdown;
