import { useEffect, useState, useRef } from 'react';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import Button from './Button';
import { Next, ShieldCross, ShieldTick } from 'iconsax-react';
import Input from './Input';
import Select from './Select';
import { OptionType } from '../@types/componentType';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCollectionOptions, getIntakeOptions } from '../api/optionsApi';
import { addLeadToCollection } from '../api/leadApi';
import { useLocation } from 'react-router-dom';

type Props = {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};
const NewTypeLead = ({ setActive }: Props) => {
  const ModalRef = useRef(null);
  const queryClient = useQueryClient();
  const locationd = useLocation();
  const [step, setStep] = useState(0);
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [location, setLocation] = useState<string>();
  const [number, setNumber] = useState<number>();

  const [collection, setCollection] = useState<OptionType>({
    label: 'Select one collection',
    value: 'none',
  });
  const [intake, setIntake] = useState<OptionType>({
    label: 'Select one intake',
    value: 'none',
  });
  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);

  const { data: IntakeOptions } = useQuery(['inatke-options'], getIntakeOptions);

  const { data: CollectionOptions } = useQuery(
    ['collection-options', intake.value, locationd.pathname.split('/')[3].toUpperCase()],
    () => getCollectionOptions(intake.value, locationd.pathname.split('/')[3].toUpperCase()),
    {
      enabled: intake.value !== 'none',
    }
  );
  const { mutate: addMutate } = useMutation(addLeadToCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries(['leads']).then(() => setActive(false));
    },
  });

  const InputFields = [
    {
      label: 'Name',
      name: 'name',
      value: name,
      setValue: setName,
      type: 'text',
    },
    {
      label: 'Phone Number',
      name: 'number',
      value: number,
      setValue: setNumber,
      type: 'number',
    },
    {
      label: 'Email',
      name: 'email',
      value: email,
      setValue: setEmail,
      type: 'text',
    },
    {
      label: 'Location',
      name: 'location',
      value: location,
      setValue: setLocation,
      type: 'text',
    },
  ];

  return (
    <div className='bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center'>
      <div className='bg-[#eee]  py-6 w-[30%] flex flex-col gap-5 relative rounded-2xl px-8' ref={ModalRef}>
        <h3 className='font-[700] text-[24px]'>New Lead</h3>
        {step === 0 && (
          <div className='flex flex-col gap-6'>
            <form className='flex flex-col gap-3'>
              {InputFields.map((item: any, index) => (
                <Input {...item} key={index} />
              ))}
            </form>
            <div className='flex gap-3'>
              <Button
                event={() => {
                  setStep(1);
                }}
                text={'Next'}
                icon={Next}
                variant={'Linear'}
                styles='font-[600]'
              />
              <Button
                event={() => {
                  setActive(false);
                }}
                text={'Cancel'}
                icon={ShieldCross}
                variant={'Linear'}
                isStrong={true}
              />
            </div>
          </div>
        )}
        {step === 1 && (
          <div className='flex flex-col gap-6'>
            <form className='flex flex-col gap-3'>
              <div className='flex flex-col gap-1'>
                <label className='text-base font-[300]'>Intake</label>
                <Select setValue={setIntake} value={intake} options={IntakeOptions} />
              </div>
              <div className='flex flex-col gap-1'>
                <label className='text-base font-[300]'>Collection</label>
                <Select
                  setValue={setCollection}
                  value={collection}
                  isDisabled={intake.value === 'none'}
                  options={CollectionOptions?.length === 0 ? [{ label: 'No Collections Found', value: 'none' }] : CollectionOptions}
                />
              </div>
            </form>
            <div className='flex gap-3'>
              <Button
                event={() => {
                  addMutate({
                    name: name as string,
                    location: location as string,
                    email: email as string,
                    phone: number as number,
                    intakeId: intake.value,
                    collectionId: collection.value,
                    type: locationd.pathname.split('/')[3].toUpperCase(),
                  });
                }}
                text={'Save'}
                icon={ShieldTick}
                variant={'Linear'}
              />
              <Button
                event={() => {
                  setActive(false);
                }}
                text={'Cancel'}
                icon={ShieldCross}
                variant={'Linear'}
                isStrong={true}
              />
            </div>
          </div>
        )}

        <div className='absolute right-3 cursor-pointer' onClick={() => setActive(false)}>
          <div className='red-gradient w-6 h-6 relative rounded-lg'>
            <div className='w-[3px] bg-white h-[16px] rounded-md rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
            <div className='w-[3px] bg-white h-[16px] rounded-md -rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewTypeLead;
