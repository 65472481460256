import { useState, useEffect } from 'react';
import { ValidationComponentType } from '../@types/componentType';
import { LeadDataType } from '../@types/dataTypes';
import { validCompOptions } from '../data/data';
import Select from './Select';

const ValidationComponent = ({ label, data, setData }: ValidationComponentType) => {
  interface validType {
    label: string;
    value: string;
  }
  const [validValue, setValidValue] = useState<validType>({ label: 'None', value: 'none' });

  useEffect(() => {
    if (validValue.value !== 'none' && (validValue.value === 'name' || validValue.value === 'phone' || validValue.value === 'location')) {
      data.forEach(d => {
        if (validValue.value !== label) {
          d[validValue.value] = d[label as keyof LeadDataType];
          delete d[label as keyof LeadDataType];
        }
      });
    }
  }, [data, label, validValue.value]);

  return (
    <div className='flex gap-x-8 items-center'>
      <p className='flex-1 text-start'>{label}</p>

      <div className='w-[45%]'>
        <div className='w-36'>
          <Select value={validValue} setValue={setValidValue} options={validCompOptions} />
        </div>
      </div>
    </div>
  );
};
export default ValidationComponent;
