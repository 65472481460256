type Props = {
  label: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  name: string;
};
const TextArea = ({ label, value, setValue, name }: Props) => {
  return (
    <div className='flex flex-col gap-1'>
      <label className='text-sm font-[500] text-[#333]'>{label}</label>
      <textarea
        value={value}
        onChange={e => setValue(e.target.value)}
        name={name}
        rows={7}
        className='w-full bg-[#E4E4E4] px-[12px] py-[8px] outline-none text-fade rounded-lg resize-none'
        style={{ border: '1px solid #00000025' }}
      />
    </div>
  );
};
export default TextArea;
