import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { HomeCardType } from '../@types/componentType';
import { getLeadNumberByType } from '../api/typeApi';

const HomeCard = ({ slug, label }: HomeCardType) => {
  const { data } = useQuery(['types', slug], () => getLeadNumberByType(slug));
  const navigate = useNavigate();
  return (
    <div className='linear-gradient h-[130px] aspect-[1.8] flex justify-center flex-col pl-6 cursor-pointer border-radius' onClick={() => navigate('/intakes', { state: { type: slug } })}>
      <h3 className='text-[20px] '>{label}</h3>
      <p className='mt-3 text-fade'>
        <span className='font-extrabold text-[24px] text-danger mr-2'>{data?.count}</span> Leads
      </p>
    </div>
  );
};
export default HomeCard;
