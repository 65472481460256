import { createCollectionType, deleteCollectionType } from '../@types/functionTypes';
import axiosInstance from '../configs/axiosConfig';
import { startLoading } from '../redux/features/authSlice';

export const createNewCollection = async ({ data, intakeId, type, title, source, dispatch }: createCollectionType) => {
  dispatch(startLoading());
  const res = await axiosInstance.post(`/collections`, {
    title,
    intakeId,
    type,
    source,
  });
  data.forEach(async item => {
    const lead = await axiosInstance.post('/leads', { ...item, intakeId, type, collectionId: res.data._id });
    await axiosInstance.put<object>(`/collections/${res.data._id}`, { id: lead.data._id });
  });
  // dispatch(stopLoading());
};

export const getCollectionList = async (id: string, type: string) => {
  const res = await axiosInstance.get(`/collections/${type}/${id}`);

  return res.data;
};

export const deleteCollectionList = async ({ id }: deleteCollectionType) => {
  await axiosInstance.delete(`/collections/${id}`);
};
