import { useState } from 'react';
import styled from 'styled-components';
import { Logo } from '../assets';
import { SidebarLinks } from '../data/NavbarData';
import { LogoutCurve } from 'iconsax-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useDispatch } from 'react-redux';
import { tokenLogout } from '../redux/apiCalls/apiAuth';
import LogoutPopup from './LogoutPopup';

const SidebarContainer = styled.div`
  height: 100vh;
  width: 18%;
  background: linear-gradient(170.71deg, #fefefe 7.03%, #dcdcdc 93.71%);
  padding: 2.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const { refreshToken } = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const [logoutActive, setLogoutActive] = useState<boolean>(true);
  const { userType } = useSelector((state: RootState) => state.auth.user);
  const activeStyle = {
    color: '#E10000',
    borderLeft: '7px solid #E10000',
    borderRight: '7px solid #E10000',
    paddingLeft: '25px',
  };
  return (
    <SidebarContainer>
      <div>
        <div className='flex justify-center flex-col items-center '>
          <img src={Logo} alt='' className='w-40 h-full object-contain cursor-pointer' onClick={() => navigate('/')} />
          {/* <span className='mt-2 font-normal text-xl'>Lead Center</span> */}
        </div>
        <div className='mt-16 flex flex-col gap-y-6'>
          {/* Map through all the Sidebar Links to display link but check if the value for restricted is true and userType is not admin or god then return null */}
          {SidebarLinks.map((link, index) => {
            if (link.restricted && userType !== 'admin' && userType !== 'god') {
              return null;
            }
            const Icon = link.icon;
            return (
              <NavLink className={`w-full flex items-center py-2  pl-8 cursor-pointer `} to={link.path} end style={({ isActive }) => (isActive ? activeStyle : undefined)} key={index}>
                <Icon variant='Bold' size='21px' className='mr-2 text-inherit' /> <span className='text-inherit'>{link.label}</span>
              </NavLink>
            );
          })}
        </div>
      </div>
      <div className='text-text w-full mx-auto'>
        <div className={`flex justify-center items-center`}>
          <div className=' flex justify-center items-center  cursor-pointer' onClick={() => tokenLogout(refreshToken, dispatch)}>
            <LogoutCurve variant='Bold' size='21px' className='mr-2' /> <span>Logout</span>
          </div>
        </div>
      </div>
    </SidebarContainer>
  );
};
export default Sidebar;
