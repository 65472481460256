import { useQuery } from '@tanstack/react-query';
import { Eye, PenAdd, Trash } from 'iconsax-react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListTemplateProps } from '../@types/componentType';
import { collectionType, intakeType } from '../@types/dataTypes';
import { getCollectionList } from '../api/collectionApi';

import { RootState } from '../redux/store';
import Button from './Button';

const ListTemplate = ({ title, type, setDelete, setCreate, setToBeDeleteId }: ListTemplateProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { intakes } = useSelector((state: RootState) => state.intakes);
  const { data: collections } = useQuery<collectionType[]>(
    ['collections', location.state.id, location.state.type.toLowerCase()],
    () => getCollectionList(location.state.id.toLowerCase(), location.state.type),
    {
      enabled: type === 'collection',
    }
  );

  return (
    <>
      <div className='w-full flex justify-between items-center'>
        <h3 className='font-[600] text-xl'>{title} Leads</h3>

        <div className='flex gap-4'>
          <Button
            text={type === 'intake' ? 'Create New Intake' : 'Create New Collection'}
            event={() => {
              setCreate(true);
            }}
            icon={PenAdd}
          />
          <Button
            text={`View  ${title} leads`}
            event={() => {
              // navigate('/leads');
              type === 'intake' ? navigate(`/leads/type/${location.state.type.toLowerCase()}`) : navigate(`/leads/intake/${location.pathname.split('/')[2]}`);
            }}
            icon={Eye}
          />
        </div>
      </div>
      <div className='mt-6 w-full border-solid border border-[#00000025] border-b-0 rounded-md overflow-hidden'>
        <div className='w-full flex bg-[#F7F7F7] px-4  py-2 ' style={{ borderBottom: '1px solid #00000025' }}>
          <div className={`flex-${type === 'collection' ? '[6]' : '[8]'}`}>Name</div>
          {type === 'collection' && <div className='flex-[3]'>Source</div>}
          <div className='flex-[3]'>Date Modified</div>
          <div className='flex-[6]'>Total Number of Data</div>
          <div className='flex-[3]'></div>
        </div>
        {type === 'collection' && (
          <>
            {collections?.length !== 0 &&
              collections?.map((item, index) => (
                <div className='w-full flex bg-[#F7F7F7] px-4  py-2 ' style={{ borderBottom: '1px solid #00000025' }} key={index}>
                  <div className='flex-[6]'>{item.title}</div>
                  <div className='flex-[3]'>{item.source}</div>
                  <div className='flex-[3]'>{moment(item.updatedAt).subtract(10, 'days').calendar()}</div>
                  <div className='flex-[6]'>{item.leads.length}</div>
                  <div className='flex-[3] flex items-center gap-4 cursor-pointer'>
                    <Button
                      text={'View'}
                      event={() => {
                        navigate(`/leads/collection/${item._id}`, {
                          state: {
                            intakeId: location.state.id,
                            type: location.state.type,
                            collectionId: item._id,
                          },
                        });
                      }}
                    />
                    <Trash
                      size={'22'}
                      variant={'Bold'}
                      color={'#E10000'}
                      onClick={() => {
                        setDelete(true);
                        setToBeDeleteId(item._id as string);
                      }}
                    />
                  </div>
                </div>
              ))}
            {(collections?.length === 0 || typeof collections === 'undefined') && (
              <div className='w-full flex bg-[#F7F7F7] px-4  py-2 justify-center text-fade' style={{ borderBottom: '1px solid #00000025' }}>
                No Collections found
              </div>
            )}
          </>
        )}
        {type === 'intake' && (
          <>
            {intakes.length !== 0 &&
              intakes.map((item: intakeType, index) => (
                <div className='w-full flex bg-[#F7F7F7] px-4  py-2 ' style={{ borderBottom: '1px solid #00000025' }} key={index}>
                  <div className='flex-[8]'>{`${item.month} ${item.year}`}</div>
                  <div className='flex-[3]'>{moment(item.updatedAt).subtract(10, 'days').calendar()}</div>
                  <div className='flex-[6]'>{item.count}</div>
                  <div className='flex-[3] flex items-center gap-4 cursor-pointer'>
                    <Button
                      text={'View'}
                      event={() => {
                        navigate(`/intakes/${item._id}`, {
                          state: {
                            type: location.state.type,
                            id: item._id,
                            title: `${item.month} ${item.year}`,
                          },
                        });
                      }}
                    />
                    {/* <Trash
                      size={'22'}
                      variant={'Bold'}
                      color={'#E10000'}
                      onClick={() => {
                        setDelete(true);
                      }}
                    /> */}
                  </div>
                </div>
              ))}
            {intakes.length === 0 && (
              <div className='w-full flex bg-[#F7F7F7] px-4  py-2 justify-center' style={{ borderBottom: '1px solid #00000025' }}>
                No intakes found
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ListTemplate;
