import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Add, Call, Map, ProgrammingArrow, SecurityUser, UserSquare } from 'iconsax-react';
import DetailTemplate from '../components/DetailTemplate';
import { ActionDataType, LeadDataType } from '../@types/dataTypes';
import ActionTemplate from '../components/ActionTemplate';
import { useState } from 'react';
import NewAction from '../components/NewAction';
import { useQuery } from '@tanstack/react-query';
import { getLeadDetailById } from '../api/leadApi';
import { getActionsByLeadId } from '../api/actionsApi';

const Container = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: row;
  gap: 4rem;
`;

const LeadProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const { data } = useQuery<LeadDataType>(['leads', location.pathname.split('/')[2]], () => getLeadDetailById(location.pathname.split('/')[2]));
  const { data: actions } = useQuery<ActionDataType>(['actions', location.pathname.split('/')[2]], () => getActionsByLeadId(location.pathname.split('/')[2]));

  const Leadvalues = [
    {
      icon: UserSquare,
      value: data?.name,
    },
    {
      icon: Call,
      value: data?.phone,
    },
    {
      icon: Map,
      value: data?.location,
    },
  ];

  const Otherdetail = [
    {
      icon: SecurityUser,
      value: data?.counsellor,
    },
    {
      icon: ProgrammingArrow,
      value: data?.status,
    },
  ];

  return (
    <Container>
      <div className='flex-[2]'>
        <div className='font-[300] text-lg cursor-default'>
          <span className='text-danger font-[400] cursor-pointer' onClick={() => navigate('/leads')}>
            Leads
          </span>{' '}
          {'>'} {data?.name}
        </div>

        <div className='w-full flex flex-col gap-6'>
          <div className='text-2xl font-[700] w-full '>Actions</div>
          {(actions === undefined || JSON.stringify(actions) === '{}') && <div className='text-fade m-auto mt-8'>There are no actions yet!</div>}
          <div className='flex flex-col gap-5'>
            {typeof actions === 'object' &&
              Object.keys(actions).map((item: string, index: any) => {
                return <ActionTemplate key={index} date={item} actions={actions[item]} />;
              })}
          </div>
        </div>
      </div>
      <div className='flex-1 flex flex-col gap-6 sticky top-[100px] h-max right-0 '>
        <DetailTemplate title={'Leads Details'} data={Leadvalues} />
        <DetailTemplate title={'Other Details'} data={Otherdetail} />
      </div>
      <div className='fixed bottom-4 flex items-center justify-center right-5 linear-gradient h-12 w-12 rounded-full border border-[#00000015] cursor-pointer' onClick={() => setIsCreateOpen(true)}>
        <Add className='text-danger' size={28} />
      </div>
      {isCreateOpen && <NewAction setActive={setIsCreateOpen} leadId={location.pathname.split('/')[2]} />}
    </Container>
  );
};
export default LeadProfile;
