import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { useThemeContext } from './context/ThemeContextProvider';
import { Home, Login, Counsellors, Calendar, IntakeList, CollectionList, LeadProfile, LeadTable } from './pages';

import './App.css';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import Copywrite from './components/Copywrite';
import Loading from './components/Loading';
import { io, Socket } from 'socket.io-client';
import Agents from './pages/Agents';
import Test from './pages/Test';
import { isBrowser } from 'react-device-detect';
import MyTasks from './pages/MyTasks';
import ViewTasks from './pages/ViewTasks';

const RootContainer = styled.main`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.body};
`;

function App() {
  const [socket, setSocket] = useState<Socket>();
  const { theme } = useThemeContext();
  const { isLoading } = useSelector((state: RootState) => state.auth);
  const { user } = useSelector((state: RootState) => state.auth);
  const hasUser = useSelector((state: RootState) => state.auth.isLoggedIn);
  const otherPageClass = 'flex flex-col w-[82%]';
  const authPageClass = 'w-full';

  useEffect(() => {
    setSocket(io('https://stromserver.leaddres.com'));
  }, []);

  useEffect(() => {
    if (hasUser && socket) {
      socket?.emit('newUser', user?._id);
    }
  }, [hasUser, socket, user?._id]);
  return (
    <ThemeProvider theme={theme}>
      {isBrowser ? (
        <RootContainer>
          <BrowserRouter>
            <div className='flex w-full relative'>
              {hasUser && <Sidebar />}
              <div className={hasUser ? otherPageClass : authPageClass}>
                {hasUser && <Navbar socket={socket as Socket} />}
                <div>
                  <Routes>
                    {/* Dashboard */}
                    <Route path='/' element={hasUser ? <Home /> : <Navigate to='/login' />} />
                    {/* Authentication */}
                    <Route path='/login' element={!hasUser ? <Login /> : <Navigate to='/' />} />
                    {/* <Route path='/register' element={<Register />} /> */}
                    {/* Pages */}
                    <>
                      <Route path='/counsellors' element={hasUser ? <Counsellors /> : <Navigate to='/login' />} />
                      <Route path='/subdealers' element={hasUser ? <Agents /> : <Navigate to='/login' />} />
                      <Route path='/calendar' element={hasUser ? <Calendar /> : <Navigate to='/login' />} />
                      {(user?.userType === 'admin' || user?.userType === 'god') && <Route path='/view-tasks' element={hasUser ? <ViewTasks /> : <Navigate to='/login' />} />}
                      <Route path='/my-tasks' element={hasUser ? <MyTasks /> : <Navigate to='/login' />} />
                      <Route path='/intakes'>
                        <Route index element={hasUser ? <IntakeList /> : <Navigate to='/login' />} />
                        <Route path=':id' element={hasUser ? <CollectionList /> : <Navigate to='/login' />} />
                      </Route>
                      <Route path='/leadprofile/:id' element={hasUser ? <LeadProfile /> : <Navigate to='/login' />} />
                      {/* Tables */}
                      <Route path='/leads'>
                        <Route index element={hasUser ? <LeadTable type='all' socket={socket as Socket} /> : <Navigate to='/login' />} />
                        <Route path='type/:id' element={hasUser ? <LeadTable type='type' socket={socket as Socket} /> : <Navigate to='/login' />} />
                        <Route path='intake/:id' element={hasUser ? <LeadTable type='intake' socket={socket as Socket} /> : <Navigate to='/login' />} />
                        <Route path='collection/:id' element={hasUser ? <LeadTable type='collection' socket={socket as Socket} /> : <Navigate to='/login' />} />
                      </Route>
                      <Route path='/test' element={<Test />} />
                    </>
                  </Routes>
                </div>
              </div>
              {hasUser && <Copywrite />}
            </div>
          </BrowserRouter>
          {isLoading && <Loading />}
        </RootContainer>
      ) : (
        <div className='w-full h-[100vh] flex justify-center items-center bg-body px-4'>
          <h2 className='text-2xl text-center font-bold'>Lead Center is only available in browser view.</h2>
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
