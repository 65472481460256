import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShieldCross, Trash } from 'iconsax-react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DeleteModalType } from '../@types/componentType';
import { OutsideAlerterFunction } from '../@types/functionTypes';
import { deleteCollectionList } from '../api/collectionApi';
import { RootState } from '../redux/store';
import Button from './Button';

const DeleteModal = ({ setState, collectionId }: DeleteModalType) => {
  const ModalRef = useRef(null);
  const queryClient = useQueryClient();
  const { isLoading } = useSelector((state: RootState) => state.auth);

  const useOutsideAlerter: OutsideAlerterFunction = ref => {
    useEffect(() => {
      function handleClickOutside(event: globalThis.MouseEvent) {
        if (ref.current && !ref.current.contains(event.target) && !isLoading) {
          setState(false);
        }
      }
      document.addEventListener('mousedown', e => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);

  const { mutate: deleteCollectionMutate } = useMutation(deleteCollectionList, {
    onSuccess: () => {
      queryClient.invalidateQueries(['collections']);
      setState(false);
    },
  });

  return (
    <div className='bg-[#1e1e1e45] backdrop-blur-sm w-full h-screen fixed top-0 left-0 flex justify-center items-center'>
      <div className=' px-8 py-4 bg-[#eee] w-1/4 rounded-xl' ref={ModalRef}>
        <h3 className='text-xl font-[600] mb-2'>Are you sure?</h3>
        <p className='text-base font-[300]  mb-6'>Selected lead will be deleted if you agree to this action.</p>
        <div className='flex gap-2'>
          <Button
            text='Delete'
            event={() => {
              deleteCollectionMutate({ id: collectionId });
            }}
            isStrong={true}
            icon={Trash}
          />
          <Button
            text='Cancel'
            event={() => {
              setState(false);
            }}
            icon={ShieldCross}
            variant={'Linear'}
          />
        </div>
      </div>
    </div>
  );
};
export default DeleteModal;
