import axios from 'axios';
import { AppDispatch, store } from '../redux/store';
import jwt_decode from 'jwt-decode';
import { updateAccessToken } from '../redux/features/authSlice';
import { tokenLogout } from '../redux/apiCalls/apiAuth';

export const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8800/api',
  baseURL: 'https://stromserver.leaddres.com/api',
  headers: {
    'Content-type': 'application/json',
  },
});

export const unverifiedInstance = axios.create({
  // baseURL: 'http://localhost:8800/api',
  baseURL: 'https://stromserver.leaddres.com/api',
  headers: {
    'Content-type': 'application/json',
  },
});

const getToken = async (refreshToken: string, dispatch: AppDispatch) => {
  console.log('Sending Refresh Token');
  try {
    const res = await axios.post('https://stromserver.leaddres.com/api/auth/refresh', { token: refreshToken });
    // const res = await axios.post('http://localhost:8800/api/auth/refresh', { token: refreshToken });

    return res.data;
  } catch (error: any) {
    if (error?.response?.data.logout) {
      tokenLogout(refreshToken, dispatch);
    }

    return;
  }
};

axiosInstance.interceptors.request.use(async config => {
  const state = store.getState();
  let currentDate = new Date();
  const decodedToken: any = jwt_decode(state.auth.user.accessToken);
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    const { accessToken } = await getToken(state.auth.user.refreshToken, store.dispatch);
    store.dispatch(updateAccessToken({ accessToken }));
    config.headers['token'] = `Bearer ${accessToken}`;
  } else {
    config.headers['token'] = `Bearer ${state.auth.user.accessToken}`;
  }
  config.params = config.params || {};
  return config;
});

export default axiosInstance;
