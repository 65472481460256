import { createNewActionType } from '../@types/functionTypes';
import axiosInstance from '../configs/axiosConfig';

export const getActionsByLeadId = async (leadid: string) => {
  const res = await axiosInstance.get(`/actions/lead/${leadid}`);
  return res.data;
};

export const createNewAction = async ({ leadId, takenBy, title, remarks }: createNewActionType) => {
  await axiosInstance.post('/actions/', {
    leadId,
    takenBy,
    title,
    remarks,
  });
};
