import axiosInstance from '../../configs/axiosConfig';
import { intakeStart, intakeSuccess, intakeFailure } from '../features/intakeSlice';
import { AppDispatch } from '../store';

export const getIntake = async (dispatch: AppDispatch) => {
  dispatch(intakeStart());
  try {
    const res = await axiosInstance.get('/intakes');
    dispatch(intakeSuccess(res.data));
  } catch (err) {
    dispatch(intakeFailure());
  }
};

export const createIntake = async (intake: { month: string; year: string }, dispatch: AppDispatch) => {
  try {
    await axiosInstance.post('/intakes', intake);
    getIntake(dispatch);
  } catch (err: any) {
    console.debug(err);
    return err.response.status;
  }
};
