import { addALeadByIdType, IAddToCollection } from '../@types/functionTypes';
import { LeadDataType } from '../@types/dataTypes';
import axiosInstance from '../configs/axiosConfig';

export const getAllLeads = async () => {
  const res = await axiosInstance.get('/leads');
  return res.data;
};

export const getCollectionSpecificLeads = async (collectionId: string) => {
  const res = await axiosInstance.get(`/leads/collection/${collectionId}`);
  return res.data;
};

export const getIntakeSpecificLeads = async (intakeId: string) => {
  const res = await axiosInstance.get(`/leads/intake/${intakeId}`);
  return res.data;
};

export const getTypeSpecificLeads = async (type: string) => {
  const res = await axiosInstance.get(`/leads/type/${type}`);
  return res.data;
};

export const updateALeadById = async ({ id, data }: addALeadByIdType) => {
  const res = await axiosInstance.put(`/leads/${id}`, data);
  if (res.data.isCounsellor) {
    return {
      changed: true,
      name: data.counsellor,
      lead: id,
    };
  } else {
    return {
      changed: false,
    };
  }
};

export const getLeadDetailById = async (id: string) => {
  const res = await axiosInstance.get(`/leads/${id}`);
  return res.data;
};

export const addLeadToCollection = async (payload: IAddToCollection) => {
  const res = await axiosInstance.post('/leads/', payload);
  await axiosInstance.put(`/collections/${payload.collectionId}`, { id: res.data._id });
};

export const deleteALead = async (leadId: string) => {
  await axiosInstance.delete(`/leads/${leadId}`);
};

export const getChartData = async (month: number) => {
  const res = await axiosInstance(`/leads/chart/${month}`);
  return res.data;
};
