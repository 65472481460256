import { Home2, Icon, People, Profile2User, TableDocument, Kanban, TaskSquare } from 'iconsax-react';

interface LinkType {
  label: string;
  path: string;
  icon: Icon;
  restricted?: boolean;
}

export const SidebarLinks: LinkType[] = [
  {
    label: 'Home',
    path: '/',
    icon: Home2,
  },
  {
    label: 'All leads',
    path: '/leads',
    icon: TableDocument,
  },
  {
    label: 'View All Tasks',
    path: '/view-tasks',
    icon: Kanban,
    restricted: true,
  },
  {
    label: 'My Tasks',
    path: '/my-tasks',
    icon: TaskSquare,
  },
  {
    label: 'Counsellors',
    path: '/counsellors',
    icon: People,
  },
  {
    label: 'Sub Dealers',
    path: '/subdealers',
    icon: Profile2User,
  },
];
