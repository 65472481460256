import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { ColumnDirective, ColumnsDirective, Grid, GridComponent, Inject, PdfExport, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { useQuery } from '@tanstack/react-query';
import { ProfileAdd } from 'iconsax-react';
import { useState } from 'react';
import styled from 'styled-components';
import { getAllAgents } from '../api/agentApi';
import Button from '../components/Button';
import NewAgent from '../components/NewAgent';

type Props = {};
const AgentCounsellor = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Agents = (props: Props) => {
  let grid: Grid | null;
  const toolbarOptions: ToolbarItems[] = ['PdfExport'];
  const { data: agents } = useQuery(['agents'], getAllAgents);
  const [createActive, setCreateActive] = useState<boolean>(false);
  const toolbarClick = (args: ClickEventArgs) => {
    if (grid && args.item.id === 'grid_1334207737_0_pdfexport') {
      grid.pdfExport();
    }
  };
  return (
    <AgentCounsellor>
      <div className='flex justify-end'>
        <Button
          text='Create New Sub Dealer'
          event={() => {
            setCreateActive(true);
          }}
          icon={ProfileAdd}
        />
      </div>
      <GridComponent dataSource={agents} toolbar={toolbarOptions} toolbarClick={toolbarClick} allowPdfExport={true} ref={g => (grid = g)}>
        <ColumnsDirective>
          <ColumnDirective field='name' headerText='Sub Dealer' />
          <ColumnDirective field='area' headerText='Area' />
          <ColumnDirective field='total' headerText='Leads Provided' />
          <ColumnDirective field='converted' headerText='Leads Converted' />
        </ColumnsDirective>
        <Inject services={[Toolbar, PdfExport]} />
      </GridComponent>
      {createActive && <NewAgent setActive={setCreateActive} />}
    </AgentCounsellor>
  );
};
export default Agents;
