import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { INotificationItem } from '../@types/componentType';
import { updateNotification } from '../api/notificationApi';

const NotificationItem = ({ _id, leadId, fromId, fromUsername, newNotification, notification, type, senderImg, setActive, setRecount }: INotificationItem) => {
  const navigate = useNavigate();
  const { mutate: readMutate } = useMutation(updateNotification, {
    onSuccess() {
      setRecount(true);
    },
  });

  const handleNotificationClick = async () => {
    newNotification && readMutate(_id);
    navigate(`/leadprofile/${leadId}`);
    setActive(false);
  };
  return (
    <div className={`flex px-4 py-2 gap-4 ${!newNotification && 'opacity-60'} cursor-pointer hover:bg-[#14141515]`} onClick={handleNotificationClick}>
      <div>
        <img src={senderImg} alt='notification-sender-img' className={`w-10 h-10 rounded-full object-cover object-top `} />
      </div>
      <div>
        <h3 className='font-bold'>{fromUsername}</h3>
        <p className='text-sm'>{notification}</p>
      </div>
    </div>
  );
};
export default NotificationItem;
