import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthBackground, Logo } from '../assets';
import Button from '../components/Button';
import Input from '../components/Input';
import { login } from '../redux/apiCalls/apiAuth';
import toast, { Toaster } from 'react-hot-toast';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const dispatch = useDispatch();

  const LoginFields = [
    { label: 'Email', name: 'email', type: 'text', value: email, setValue: setEmail },
    { label: 'Password', name: 'password', type: 'password', value: password, setValue: setPassword },
  ];

  return (
    <div className='h-screen w-full flex '>
      <div className='w-[70%] bg-[#e9eaec]'>
        <img src={AuthBackground} alt='' className='w-full h-full object-contain' />
      </div>
      <div className='h-screen w-[30%] py-10 px-14 flex flex-col justify-between'>
        <div className='flex flex-col gap-14'>
          <div className='flex gap-4 items-center justify-center'>
            <img src={Logo} alt='sunway logo' className='w-[40%]' />
            {/* <p className='text-lg font-light'>Lead Center</p> */}
          </div>
          <div className='flex flex-col gap-7'>
            <h3 className='font-[600] text-2xl'>Welcome Back</h3>
            <form
              className='w-full flex flex-col gap-5'
              onSubmit={() => {
                login(dispatch, { email, password });
              }}
            >
              <div className='flex flex-col gap-3'>
                {LoginFields.map((item, index) => (
                  <Input {...item} key={index} />
                ))}
                {/* <span className='text-danger cursor-pointer'>Forgot Password?</span> */}
              </div>
              <Button
                text={'Login'}
                event={async () => {
                  const data = await login(dispatch, { email, password });
                  if (data === 401) {
                    toast.error('Wrong Login Credentials');
                  }
                }}
                styles='w-full border-b-[#00000040] py-2'
              />
            </form>
          </div>
        </div>
        {/* <p className='font-[300] flex gap-2'>
          Don't have an account yet? <span className='font-[600] cursor-pointer'>Sign Up</span>
        </p> */}
      </div>
      <Toaster />
    </div>
  );
};
export default Login;
